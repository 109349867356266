<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 40 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'mes'" :produtos="tipos_funerarios" 
      @filtrar="filterData" :title_opcao="'Situação'"
        ></filtro-component>
    </v-col>
  </v-container>

 



  <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
    
    <v-row>

        <v-col
        cols="12"
        sm="6"
        lg="4">

        <base-material-stats-card-s elevation="1"
        color="green" 
        icon="mdi-handshake"
        subIcon="mdi-information"
        subIconColor="green"
        title="Volume Negociado"
        :sub-text="'Total Esperado em Renegociação'"
        :valuex="this.bool_02"
        :value="formatarMoeda(this.total_cobranca_reneg_volume)"
        :downloadFunction="download_xls_v"
        :subTextE="'Recebido: ' + formatarMoeda(this.total_cobranca_reneg_volume_r)"
        subIconColorE="green"
        subIconE="mdi-currency-usd"
        />
        </v-col>

        <v-col
        cols="12"
        sm="6"
        lg="4">

        <base-material-stats-card-s elevation="1"
        color="orange" 
        icon="mdi-handshake"
        subIcon="mdi-information"
        subIconColor="orange"
        title="Renegociação Ativas"
        :valuex="this.bool_01"
        :value="formatarMoeda(this.total_cobranca_reneg_ativa)"
        :sub-text="'Total Esperado em Renegociações Pagas ou Aberta'"
        :downloadFunction="download_xls_a"
        :subTextE="'Recebido: ' + formatarMoeda(this.total_cobranca_reneg_ativa_r)"
        subIconColorE="orange"
        subIconE="mdi-currency-usd"
        />
        </v-col>

        <v-col
        cols="12"
        sm="6"
        lg="4">

        <base-material-stats-card-s elevation="1"
        color="red" 
        icon="mdi-handshake"
        subIcon="mdi-information"
        subIconColor="red"
        title="Renegociações Canceladas"
        :valuex="this.bool_03"
        :value="formatarMoeda(this.total_cobranca_reneg_cancelado)"
        :sub-text="'Total Esperado em Renegociações Canceladas'"
        :downloadFunction="download_xls_c"
        :subTextE="'Recebido: ' + formatarMoeda(this.total_cobranca_reneg_cancelado_r)"
        subIconColorE="red"
        subIconE="mdi-currency-usd"
        />
        </v-col>

    </v-row>

    <v-row>
    
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-account-group"
        title="Total de Acordos por Usuários"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="ranking_header"
              :items="ranking"
              :items-per-page="10" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      

                        <template v-slot:item="row">
                          
                          <tr :class="{'gray-row': row.index % 2 !== 0}">
                            <td style="width: 30%;font-size:14px;">{{row.item.usuario}} </td>
                            <td style="width:12%;font-size:14px;">
                              <v-chip 
                              :color="getEmpresa(row.item.empresa)"
                              text-color="white">
                                  {{row.item.empresa}}                    
                                  
                              </v-chip>  
                            </td>

                            <td style="width: 20%;font-size:14px;"> 
                           
                              {{
                                 parseFloat(row.item.valorTotal).toLocaleString('pt-BR', {
                                   style: 'currency',
                                   currency: 'BRL'
                                 })
                               }}      
                            </td>
                            <td style="width: 20%;font-size:14px;">
                              

                              {{
                                 parseFloat(row.item.valorRecebido).toLocaleString('pt-BR', {
                                   style: 'currency',
                                   currency: 'BRL'
                                 })
                               }}     
                              
                            </td>
                            <td style="width: 25%;font-size:14px;">{{row.item.percentualRecebido}} %</td>

                            
                          </tr>
                      </template>
              </v-data-table>


    <br/>

  

          </base-material-card>
  
    </v-col>

  

  </v-row>

<v-row>
    
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-account-group"
        title="Renegociação - Composição"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 


        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar ID do Acordo"
          single-line
          hide-details
        ></v-text-field>
        <br/>

            <v-data-table
              :headers="lista_composicao_header"
              :items="lista_composicao"
              :items-per-page="20" 
              items-per-page-text="Linhas por página"
              group-by="id"
              show-group-by
              :search="search"
              class="elevation-0">
      

                        <template v-slot:item="row">
                         

                        <tr style="background-color:#eeeeee">
                          <td v-if="row.index === 0" style="width:15%;font-size:12px">Data do Acordo:
                             {{ formatarData(row.item.data_acordo) }}</td>

                          <td v-if="row.index === 0" style="width: 20%;font-size:12px">Total da Composição:
                            {{
                              parseFloat(row.item.soma_parcela_original).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            }}
                          </td>

                          <td v-if="row.index === 0" style="width: 15%;font-size:12px"> Total da Reneg:
                            {{
                              parseFloat(row.item.total_reneg).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            }}
                          </td>


                          <td v-if="row.index === 0" style="width:12%;font-size:12px;">
                            <v-chip :color="getEmpresa(row.item.empresa)" text-color="white">
                              {{row.item.empresa}}                    
                            </v-chip>
                          </td>

                          <td  v-if="row.index === 0" style="font-size:12px">Qtd. Parcelas Geradas:{{row.item.parcelamento}}</td>

                          <td v-if="row.index === 0" style="width: 20%;font-size:12px"> Total Pago:
                            {{
                              parseFloat(row.item.total_pago).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            }}
                          </td>

                          <td v-if="row.index === 0" style="width:12%;font-size:12px;">
                            <v-chip outlined :color="getStatus(row.item.situacao)" >
                              {{row.item.situacao}}                    
                            </v-chip>
                          </td>

                          <td v-if="row.index === 0" style="width:12%;font-size:12px;">
                            <v-chip outlined :color="getPago(row.item.total_reneg, row.item.total_pago)" >
                              {{getPagoDescricao(row.item.total_reneg, row.item.total_pago)  }}           
                            </v-chip>
                          </td>

                          

                        </tr>

                      
                         <!-- Nova linha para vencimento_original -->
                        <tr style="font-size:14px;">
                          <td style="text-align:right;font-size:12px;">
                            Parcela: #{{row.index + 1}} 
                          </td>
                          <td style="text-align:center;font-size:12px;">
                            {{row.item.ref}}
                          </td>
                          <td style="text-align:center;font-size:12px;">
                            {{ formatarData(row.item.vencimento_original) }}
                          </td>

                          <td style="text-align:center;font-size:12px;">
                          {{
                                  parseFloat(row.item.parcela_orignal).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  })
                                }}
                          </td>
                          
                          <td colspan="4" style="text-align:left;font-size:12px;">
                              <v-chip v-if="row.item.obs !== ''"
                                  class="ma-2"
                                  color="pink" style="height:45px"
                                  label
                                  text-color="white"
                                >
                                  <v-icon left>
                                    mdi-label
                                  </v-icon>
                                <span  v-html="formatarObs(row.item.obs)"></span>
                              </v-chip>
                
                          </td>
                          
                        </tr>


                      </template>
              </v-data-table>

 <br/>
  
      <div class="d-flex justify-end" style="color:green; font-size: 48px; margin-right: 12px;">
        <span @click="download_xls_acordo_composicao" style="cursor: pointer;"><i class="fas fa-file-excel"></i></span>
      </div>

    <br/>

  

          </base-material-card>
  
    </v-col>

  

  </v-row>



  </v-container>

</v-app> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Cobranca from '../../services/cobranca'
import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto
import { ContentLoader } from 'vue-content-loader'


  export default {
    name: 'Cobranca',
  components: {
    apexcharts: VueApexCharts,
    FiltroComponent,
    ContentLoader,

  },
  
  created() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      this.selectedStartDate = `${"01"}/${month}/${year}`;
      this.selectedEndDate =  this.formatDateBR(new Date())
     
    },
    
  mounted() {

    this.dashboard();
   
    
  },

 
    data () {
      return {
       
        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          empresa: 'Todas',
          search: "",
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "",
          selectMode: "",
                   produto: 'Todos',
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: [],
          modos: ["Cadastro"],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos", "Serviços Cemiteriais"],

          bool_01:true,
          bool_02:true,
          bool_03:true,
          bool_04:true,
          showProgress: false,
          currentGroup: null,
          shownDataAcordo: new Set(),


          tipos_funerarios: [
                "Todos",
              ],
        


        ranking_header: [
          { text: 'Usuário', value: 'usuario' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Valor Total', value: 'valorTotal' },
          { text: 'Valor Recebido', value: 'valorRecebido' },
          { text: 'Percentual', value: 'percentualRecebido' },

        ],

        ranking: [],
        lista_composicao: [],

         lista_composicao_header: [
          { text: 'Acordo', value: 'id' },
          { text: 'Data', value: 'data_acordo' },
                    { text: 'Total Original', value: 'soma_parcela_original' },
          { text: 'Total Renegociado', value: 'total_reneg' },
          { text: 'Parcela Original', value: 'vencimento_original' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Parcelas', value: 'parcelamento' },
          { text: '', value: '' },
          { text: '', value: '' },


        ],

          total_cobranca_reneg_cancelado: 0,
          total_cobranca_reneg_volume: 0,
          total_cobranca_reneg_ativa: 0,

          total_cobranca_reneg_cancelado_r: 0,
          total_cobranca_reneg_volume_r: 0,
          total_cobranca_reneg_ativa_r: 0

      }
  },

    methods: {


      getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },

        formatarData(data) {
          if (!data) return ''; // Verificar se a data não está vazia ou indefinida

          // Dividir a data em ano, mês e dia
          const partes = data.split('-');
          if (partes.length !== 3) return data; // Se a data não estiver no formato esperado, retornar a data original

          // Montar a data no formato desejado (dd/mm/aaaa)
          const dia = partes[2];
          const mes = partes[1];
          const ano = partes[0];
          return `${dia}/${mes}/${ano}`;
        },

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },
            formatarObs(obs) {
              // Substituir \n por <br> para exibir corretamente no HTML
              return obs.replace(/\n/g, '<br>');
            },


       filterData(valores) {

        this.isLoading = true;
         let nova_consulta = false;

        
       
          if (this.selectedStartDate !== valores.selectedStartDate || this.selectedEndDate !== valores.selectedEndDate
          || this.selectedCompany !== valores.selectedCompany
          || this.selectedType !== valores.selectedType) {
            nova_consulta = true;
          }

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.produto = "Todos";



          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;


        if(nova_consulta){
           
          this.total_cobranca_reneg_volume = 0,
          this.total_cobranca_reneg_cancelado = 0,
          this.dashboard();

        }else{
            console.log('Valor'," Recerregar Dados");
            
        }

      },


      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

     
    calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },

      download_xls_c(){
          this.download_xls_reneg(0)
      },

      download_xls_a(){
          this.download_xls_reneg(1)
      },

      download_xls_v(){
          this.download_xls_reneg(2)
      },


          download_xls_reneg(status) {
             
              
             this.showProgress = true;

             
             const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
             const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
             const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

             if (diferencaEmDias > 40) {
               this.isLoading = false;
               this.showFilter = true;
               this.showProgress = false;
               return 
             }
             Cobranca.lista_reneg_cobranca_xls(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, status)
             .then(response => {
                      if (response.status === 200) {

                        const fileName = `volume_reneg_${Date.now()}.xlsx`;
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                            const fileLink = document.createElement('a')
                            fileLink.href = fileURL
                            fileLink.setAttribute('download', fileName)
                            document.body.appendChild(fileLink)
                            fileLink.click()

                        }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });


                     
    },


    download_xls_acordo_composicao() {
             
             this.showProgress = true;
             const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
             const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
             const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

             if (diferencaEmDias > 40) {
               this.isLoading = false;
               this.showFilter = true;
               this.showProgress = false;
               return 
             }

             Cobranca.lista_composicao_xls(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, "Todos", "")
             .then(response => {
                      if (response.status === 200) {

                        const fileName = `acordo_composicao_${Date.now()}.xlsx`;
                            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                            const fileLink = document.createElement('a')
                            fileLink.href = fileURL
                            fileLink.setAttribute('download', fileName)
                            document.body.appendChild(fileLink)
                            fileLink.click()

                        }
                     })
                     .catch(error => {
                       // Lida com erros
                       console.error('Erro ao baixar o arquivo Excel:', error);
                     }) .finally(() => {
                       // Após o término do download, oculte o diálogo de progresso
                       this.showProgress = false;
                     });


                     
    },

    shouldShowDataAcordo(id) {
      if (!this.shownDataAcordo.has(id)) {
        this.shownDataAcordo.add(id);
        return true;
      }
      return false;
    },

    getEmpresa (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

    getStatus (status) {
            switch (status) {
            case 'Ativo': return 'blue lighten-1'
            case 'Cancelado' : return 'red lighten-1'
             default: 'success'
            }
      },

     getPago(valor_titulo, valor_pago) {
          if (valor_pago >= valor_titulo) {
              return 'green lighten-1';
          } else {
              return 'red lighten-1';
          }
      },

      getPagoDescricao(valor_titulo, valor_pago) {
          if (valor_pago >= valor_titulo) {
              return 'Quitada';
          } else {
              return 'Em Aberto';
          }
      },


      dashboard() {
 
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 40) {

                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }


                   Cobranca.total_cobranca_reneg_volume( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType ).then(response => {
                      if(response.status === 200){
                      
                         this.total_cobranca_reneg_volume = response.data;
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_02 = false
                    })  

                    Cobranca.total_cobranca_reneg_cancelada( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType).then(response => {
                      if(response.status === 200){
                         this.total_cobranca_reneg_cancelado = response.data;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_03 = false
                    })  


                    Cobranca.total_cobranca_reneg_ativa( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType).then(response => {
                      if(response.status === 200){
                         this.total_cobranca_reneg_ativa = response.data;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_01 = false
                    })  



                    Cobranca.total_cobranca_reneg_volume_r( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType).then(response => {
                      if(response.status === 200){
                      
                         this.total_cobranca_reneg_volume_r = response.data;
                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_02 = false
                    })  

                    Cobranca.total_cobranca_reneg_cancelada_r( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType).then(response => {
                      if(response.status === 200){
                         this.total_cobranca_reneg_cancelado_r = response.data;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_03 = false
                    })  


                    Cobranca.total_cobranca_reneg_ativa_r( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType).then(response => {
                      if(response.status === 200){
                         this.total_cobranca_reneg_ativa_r = response.data;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_01 = false
                    })  

                    Cobranca.ranking( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany).then(response => {
                          if(response.status === 200){
                            this.ranking = response.data;

                          } 


                        }).catch(e => {    
                        }).finally(() => {
                         
                      });

                       Cobranca.acordo_composicao( converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany, this.selectedType, "Todos", "").then(response => {
                          if(response.status === 200){
                            this.lista_composicao = response.data;

                          } 


                        }).catch(e => {    
                        }).finally(() => {
                         
                      });




                    

      }

    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  .vuetify__expand-icon {
  display: none !important; /* Oculta os ícones de expandir */
}
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-data-table-header-mobile .v-data-table-header-group {
  display: none !import; /* Oculta os botões de expansão de grupo */
}
  
  </style>
